// Switch
// =======================================================================

.form-switch .form-check-label {
  .switch-icon-left,
  .switch-icon-right {
    top: 3px;
    transform: translate(0px, -2.5px) !important;
  }
}
