.form-control,
.form-select,
.form-check-input {
  &::placeholder {
    transition: all 0.2s ease;
  }

  &:focus {
    &::placeholder {
      transform: translate(5px);
      transition: all 0.2s ease;
    }
    &:valid,
    &.is-valid,
    &:invalid,
    &.is-invalid {
      box-shadow: $input-focus-box-shadow;
    }
  }
}
.input-group-merge {
  .form-select {
    &:valid,
    &.is-valid,
    &:invalid,
    &.is-invalid {
      box-shadow: none;
    }
  }
}
.form-floating {
  // .form-control,
  // .form-control-plaintext,
  // .form-select,
  textarea.form-control {
    height: auto;
  }
}

.form-control-plaintext {
  &:focus {
    outline: none;
  }
}

// Text area line height
textarea {
  &.form-control {
    line-height: 1.6rem;
    padding: 0.8rem 1rem;
  }

  &.form-control-sm {
    padding: 0.4rem 0.857rem;
  }

  &.form-control-lg {
    padding: 1rem 1.143rem;
  }
}
